import apisauce from "apisauce";
import axios from "axios";
import { Constants } from "constants/Constants";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { RouteConstants } from "routes/RouteConstants";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";

const create = (baseURL = Constants.productionBaseUrl) => {
  const token = () => localStorage.getItem("token");
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      Authorization: getItemFromLocalStorage(Constants.authorizationToken),
    },
    // timeout: 10000,
  });

  api.addMonitor((response) => {
    if (
      response.status === HttpResponseTypeEnum.Unauthorized ||
      response.status === HttpResponseTypeEnum.AccessDenied
    ) {
      localStorage.clear();
      window.location.href = RouteConstants.login;
    }
  });

  const setAuthorization = () => {
    const token = getItemFromLocalStorage(Constants.authorizationToken);
    api.headers["Authorization"] = token;
  };

  //Page Management API
  const getPageDashboard = (data: any) => {
    setAuthorization();
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`pages?${params}`);
  };

  //FAQs API based on Categories
  const getFaqList = (data: any) => {
    let params = ``;

    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`categories?categoryType=2001&${params}`);
  };
  const changeFaqsStatus = (data: any) => api.put(`category-statuses`, data);
  const changeFaqPriority = (data: any) => api.put(`category-priority`, data);
  const deleteFaqs = (data: any) =>
    api.delete(`bulk-delete-categories`, {}, { data: data });

  // Authentication APIs
  const login = async (data: any) => api.post(`authenticate`, data);
  const logout = (data: any) => api.put(`logout`);
  const forgotPassword = async (data: any) => api.post(`forgot-password`, data);
  const resetPassword = async (data: any) => {
    api.headers["Authorization"] = data.token;
    return api.post(`reset-password`, data);
  };
  const changePassword = (data: any) => {
    setAuthorization();
    return api.post(`change-password`, data);
  };

  // Role APIs
  const getRoles = () => api.get(`roles`);

  // User or Admin Management APIs
  const getUsers = (data: any) => {
    setAuthorization();

    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`admins?${params}`);
  };

  const getAdminsForFilters = () => {
    setAuthorization();
    return api.get("admins-dropdown");
  };
  const getAdminLogs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value || value === 0) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`admin-logs?${params}`);
  };
  const getUserDashboard = () => {
    setAuthorization();
    return api.get(`admin-dashboard`);
  };
  const getAllUsers = async () => api.get(`admins`);
  const inviteUsers = async (data: any) => api.post(`admins`, data);
  const getModules = () => api.get(`modules`);
  const updateAdmins = (id: number, data: any) => api.put(`admins/${id}`, data);
  const deleteUser = async (id: any) => api.delete(`admins/${id}`);

  // const getAdminLogs = () => api.get(`admin-logs`);
  const updateAdminStatus = (data: any) => api.put(`admins-statuses`, data);
  const deleteUsers = (data: any) =>
    api.delete(`bulk-delete-admins`, {}, { data: data });

  // Project Management APIs
  const getAllProjects = (data: any) => api.get(`projects`);

  const getProjectCategories = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`categories?${params}`);
  };

  //project content drafts
  const getProjectDraftList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`project-content-drafts?${params}`);
  };

  const addProjectContentDraft = (data: any) => {
    return api.post(`project-content-drafts`, data);
  };

  const updateProjectContentDraft = (data: any) => {
    return api.put(`project-content-drafts/${data?.id}`, data);
  };

  const deleteProjectContentDraft = (data: any) => {
    return api.delete(`bulk-delete-project-content-drafts`, {}, { data: data });
  };

  const bulkStatusProjectCategory = (data: any) =>
    api.put(`category-statuses`, data);

  const updatePromiseStatus = (data: any) => {
    data.admins.map((id: any) => {
      api.post(`promises`, { id: id, status: data.status });
    });
  };

  const allProjectFaqs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`faqs?typeOfFAQ=3002&${params}`);
  };
  const getProjectFaqs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`faqs?${params}`);
  };
  const getProjectCollectionList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`project-collections?${params}`);
  };

  const getAllProjectContentList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`project-contents?${params}`);
  };
  const deleteProjectCollectionList = (data: any) =>
    api.delete(`bulk-delete-project-collections`, {}, { data: data });
  const changeProjectCollectionListStatus = (data: any) =>
    api.put(`project-collections`, data);

  const postProjectCollectionCreation = (data: any) =>
    api.post(`project-collections`, data);
  const getProjectCollectionById = (id: any) =>
    api.get(`project-collections/${id}`);
  const deleteProjectCollectionById = (id: any) =>
    api.delete(`project-collections/${id}`);
  const updateProjectCollectionById = (id: any, data: any) =>
    api.put(`project-collections/${id}`, data);

  const verifyProjectLauchPhase = (id: any) => {
    return api.get(`verify-launch-phases/launch-phases/${id}`);
  };

  const getProjectCrmFetchUpdates = (id: any) => {
    return api.get(`difference-in-buckets?projectContentId=${id}`);
  };

  // Page Management APIs
  const addPageManagementData = (data: any) => api.post("add-page", data);
  const updatePageManagementData = (data: any) =>
    api.put(`pages/${data.id}`, data);
  const getPageManagementData = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`get-page?${params}`);
  };
  const getPortfolioPage = (pageType: string) =>
    api.get(`get-page?pageType=${pageType}`);
  const addPortfolioPage = (data: any) => api.post(`add-page`, data);
  const updatePortfolioPage = (data: any) => api.put(`pages/${data.id}`, data);
  const getProjectContents = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`project-contents?${params}`);
  };

  // Promise Management APIs
  const getPromises = (data: any) => {
    setAuthorization();

    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`promises?${params}`);
  };

  const getAllPromises = () => api.get(`promises`);

  const promiseStatus = (data: any) => {
    return api.put(`promise-statuses`, data);
  };

  const updatePromisePriority = (data: any) => {
    return api.put(`promise-priority`, data);
  };

  const getPromiseDashboard = () => {
    setAuthorization();
    return api.get(`promise-dashboard`);
  };
  const bulkDeletePromises = (data: any) =>
    api.delete(`bulk-delete-promises`, {}, { data: data });
  const postPromises = (data: any) => api.post(`promises`, data);
  const updatePromises = (id: number, data: any) =>
    api.put(`promises/${id}`, data);
  const postPromisesDraft = (data: any) => api.post(`promises-draft`, data);
  const updatePromisesDraft = (id: number, data: any) =>
    api.put(`promises-draft/${id}`, data);
  const updatePromisesStatus = (data: any) => api.put(`promise-statuses`, data);
  const removePromises = (id: number) => api.delete(`promises/${id}`);

  // Resource Management APIs
  const getAllFaqs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (
          data["byPrority"] === true ||
          data["shouldValidateAdmin"] === false
        ) {
          if (key != "sortingPriority" && key != "sortingKey") {
            params += `${key}=${value}&`;
          }
        } else {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`faqs?${params}`);
  };

  const deleteFaq = (data: any) => {
    if (data.admins.length > 0) {
      data.admins.map((id: any) => api.delete(`faqs/${id}`));
    }
  };

  const updateFaq = (data: any) => {
    if (data.admins.length > 0) {
      data.admins.map((id: any) => {
        api.put(`faqs/${id}`, { status: data.status });
      });
    }
  };

  const updateFaqPriority = (data: any) => {
    api.put(`faqs/${data.id}`, { priority: data.priority });
  };
  //General Faqs APIs
  const allFaqs = (data: any) => api.get(`faqs?categoryId=${data}`);
  const createFaq = (data: any) => api.post(`faqs`, data);
  const updateGeneralFaq = (id: number, data: any) => {
    return api.put(`faqs/${id}`, data);
  };
  const bulkUpdateGeneralFaq = (data: any) => api.put(`faq-statuses`, data);
  const bulkDeleteGeneralFaq = (data: any) =>
    api.delete(`bulk-delete-faqs`, {}, { data: data });
  const deleteGeneralFaq = (id: any) => api.delete(`faqs/${id}`);

  const getAllCategories = (param: any) =>
    api.get(`categories?categoryType=${param}&shouldValidateAdmin=${false}`);
  const addNewCategory = (data: any) => api.post(`category`, data);
  const deleteCategory = (id: any, data: any) =>
    api.delete(`category/${id}`, data);
  const putCategory = (id: any, data: any) => api.put(`category/${id}`, data);
  const faqPriorityUpdate = (data: any) => {
    return api.put(`faq-priority`, data);
  };

  // const getResourceDashboard = () => api.get(`resource-dashboard`);
  const getCategories = () => api.get(`categories`);

  const getResourceDashboard = (data: any) => {
    setAuthorization();
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`resource-dashboard?${params}`);
  };

  //insights Creation
  const postInsightsCreation = (data: any) => api.post(`insights`, data);

  const getAllInsightsCreation = () => api.get(`insights`);

  const getInsightsById = (id: any) => api.get(`insights/${id}`);

  const updateInsightsCreation = (data: any) =>
    api.put(`insights/${data.id}`, data.data);

  const updateInsightsStatuses = (data: any) =>
    api.put(`insight-statuses`, data);

  const deleteInsightsCreation = (id: any) => {
    return api.delete(`insights/${id}`);
  };

  // Marketing Management APIs
  const getReferrals = () => api.get("nudge-cards");
  const addTestimonial = async (data: any) => api.post(`testimonials`, data);
  // const deleteTestimonial = async (id: any) => api.delete(`testimonials/${id}`);

  //page management APIs

  const getProjectContentList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`project-contents`, data);
  };
  const getProjectHoablPage1ProjectList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (key === "shouldValidateAdmin") {
          params += `${key}=${value}&`;
        } else if (value) {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`project-contents?${params}`);
  };
  const getHoablPage1 = () => api.get(`get-page?pageType=${5001}`);
  const updateHoablPage1 = (data: any) => api.put(`pages/${data.id}`, data);
  const createHoablPage1 = (data: any) => api.put(`add-page`, data);

  const deleteTestimonial = (data: any) =>
    api.delete(`bulk-delete-testimonials`, {}, { data: data });
  const updateTestimonial = async (data: any) =>
    api.put(`testimonials/${data.id}`, data);
  const getTestimonials = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`testimonials?${params}`);
  };
  const changeTestimonialStatus = (data: any) =>
    api.put(`testimonial-statuses`, data);
  const updateTestimonialPriority = (data: any) =>
    api.put(`testimonial-priority`, data);

  //MediaAndPr
  const addMediaAndPr = async (data: any) => api.post(`media-and-prs`, data);
  const deleteMediaAndPr = (data: any) =>
    api.delete(`bulk-delete-media-and-prs`, {}, { data: data });
  const updateMediaAndPr = async (data: any) =>
    api.put(`media-and-prs/${data.id}`, data);
  const getMediaAndPrs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`media-and-prs?${params}`);
  };
  const changeMediaAndPrStatus = (data: any) =>
    api.put(`media-and-prs-statuses`, data);
  const updateMediaAndPrPriority = (data: any) =>
    api.put(`media-and-prs-priority`, data);

  const getMarketingDashboard = () => {
    setAuthorization();
    return api.get(`marketing-dashboard`);
  };

  const getLandingPageDashboard = (data: any) => {
    setAuthorization();
    return api.get(`url-dashboard`);
  };
  const addLandingPageUrlCreation = (data: any) => {
    setAuthorization();
    return api.post(`landing-page-url`, data);
  };
  const getUrls = (data: any) => {
    setAuthorization();

    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`landing-page-url?${params}`);
  };
  const deleteUrl = (data: any) =>
    api.delete(`bulk-delete-urls`, {}, { data: data });

  const changeUrlStatus = (data: any) => api.put(`url-statuses`, data);
  // const getAllFaqs = () => api.get(`faqs`);
  const getInsights = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null) {
        if (data["byPrority"] == true) {
          if (key !== "sortingKey" && key !== "sortingPriority") {
            params += `${key}=${value}&`;
          }
        } else {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`insights?${params}`);
  };
  const updateInsightsStatus = (data: any) => api.put(`insight-statuses`, data);

  const deleteSelectedInsights = async (data: any) => {
    return api.delete(`bulk-delete-insights`, {}, { data: data });
  };

  const updateInsightPriority = (data: any) =>
    api.put(`insight-priority`, data);

  // Marketing Management APIs
  // const getReferrals = () => api.get(`nudge-cards`);

  const addUpdates = (data: any) => api.post(`marketing-updates`, data.data);
  const addBlogs = (data: any) => api.post(`marketing-updates`, data.data);

  const updateMarketingUpdates = (data: any) =>
    api.put(`marketing-updates/${data.id}`, data.data);

  const updateBlogs = (data: any) =>
    api.put(`marketing-updates/${data.id}`, data.data);

  const addDraftMarketingUpdates = (data: any) => {
    api.post(`marketing-updates-draft`, data.data);
  };

  const addDraftBlogs = (data: any) => {
    api.post(`marketing-updates-draft`, data.data);
  };

  const getSingleUpdate = (id: any) => api.get(`marketing-updates/${id}`);
  const getSingleBlog = (id: any) => api.get(`marketing-updates/${id}`);

  const deleteUpdates = (id: any) => api.delete(`marketing-updates/${id}`);
  const deleteSingleBlog = (id: any) => api.delete(`marketing-updates/${id}`);

  // marketing update listing
  const getAllUpdates = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== "") {
        if (data["byPrority"] == true || data["shouldValidateAdmin"]) {
          if (key !== "sortingKey" && key !== "sortingPriority") {
            params += `${key}=${value}&`;
          }
        } else {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`marketing-updates?${params}`);
  };
  const getAllBlogs = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== "") {
        if (data["byPrority"] == true || data["shouldValidateAdmin"]) {
          if (key !== "sortingKey" && key !== "sortingPriority") {
            params += `${key}=${value}&`;
          }
        } else {
          params += `${key}=${value}&`;
        }
      }
    }
    return api.get(`marketing-updates?${params}`);
  };

  // const updatePriority = (data: any) => {
  //   api.put(`marketing-updates/${data.id}`, { priority: data.createdBy });
  // };
  // const updatePriority = (data: any) => {
  //   api.put(`marketing-updates/${data.id}`, { priority: data.priority });
  // };

  const getAllUpdatesDrafts = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`marketing-updates-draft?${params}`);
  };
  const getAllBlogsDrafts = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`marketing-updates-draft?${params}`);
  };
  const deleteUpdate = (data: any) => {
    return api.delete(`bulk-delete-marketing-updates`, {}, { data: data });
  };
  const deleteBlog = (data: any) => {
    return api.delete(`bulk-delete-marketing-updates`, {}, { data: data });
  };

  const bulkUpdateDelete = (data: any) => {
    return api.delete(`bulk-delete-marketing-updates`, {
      marketingUpdateIds: data,
    });
  };
  const bulkBlogDelete = (data: any) => {
    return api.delete(`bulk-delete-marketing-updates`, {
      marketingUpdateIds: data,
    });
  };
  const updateStatus = (data: any) =>
    api.put(`marketing-update-statuses`, data);

  const blogStatus = (data: any) => api.put(`marketing-update-statuses`, data);

  const updatePriority = (data: any) =>
    api.put(`marketing-update-priority`, data);

  const blogPriority = (data: any) =>
    api.put(`marketing-update-priority`, data);

  const updateProjectPriority = (data: any) => {
    return api.put(`project-content-priority`, data);
  };
  const deleteSelectedProjects = (data: any) => {
    return api.delete(`bulk-delete-project-contents`, {}, { data: data });
  };
  const updateProjectsStatus = (data: any) => {
    return api.put(`project-content-statuses`, data);
  };
  //File Upload
  const fileUploadToS3 = (data: any) => {
    const response = api.get(
      `aws/s3/presignedUrl?type=${data.urlType}&key=${encodeURIComponent(
        data.name
      )}`
    );
    return response;
  };

  const uploadeFileToS3 = async (data: any) => {
    const res = await fetch(data.url, {
      method: "PUT",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": data.contentType,
      },
      body: data.blob,
    });
    return res;
  };

  const fileDeleteFromS3 = (data: any) => api.delete(`aws/s3/file?key=${data}`);

  // Project Content Creation APIs
  const addProjectContentCreationData = (data: any) =>
    api.post("project-contents", data);
  const updateProjectContentCreationData = (data: any) =>
    api.put(`project-contents/${data.id}`, data);
  const getProjectContentCreationData = (id: number) =>
    api.get(`project-contents/${id}`);
  const getLaunchPhasesData = (id: number) => api.get(`launch-phases/${id}`);
  const getAllLaunchPhasesData = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`launch-phases?${params}`);
  };
  const deleteProjectMappedFaq = (data: any) => {
    return api.delete(
      `project-contents-faqs/${data?.projectId}/faq/${data?.faqId}`
    );
  };

  // upcomming project API calls
  const addupCommingProjectContentCreationData = (data: any) =>
    api.post("/project-contents-comingsoon", data);
  const getUpCommigProjects = () => api.get("/project-contents-comingsoon");
  const getUpCommigSingleProject = (id: any) =>
    api.get(`/project-contents-comingsoon/${id}`);
  const updateStatusUpcommingProject = (data: any) =>
    api.post("/project-contents-commingsoon-status", data);
  const updateUpcommingProject = (id: any, data: any) =>
    api.post(`/project-contents-comingsoon/${id}`, data);
  const filterUpCommingProject = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`/project-contents-comingsoon?${params}`);
  };

  // leads API calls
  const fetchLeads = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`/leads?${params}`);
  };

  //Notifications API
  const getNotifications = (data: any) => {
    setAuthorization();

    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`notification?${params}`);
  };
  const addNotification = async (data: any) => api.post(`notification`, data);
  const updateNotification = async (data: any) =>
    api.put(`notification/${data.id}`, data);

  const getNotificationDashboard = () => {
    setAuthorization();
    return api.get(`promise-dashboard`);
  };

  // App Updates
  const getAppUpdates = (data: any) => {
    setAuthorization();

    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value !== undefined && value !== null && value !== "") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`app-update-notifications?${params}`);
  };
  const addAppUpdate = async (data: any) =>
    api.post(`app-update-notifications`, data);
  //Chat Management
  const addChatManagementData = (data: any) => api.post("autoChat", data);

  const getChatManagementData = (id: number) => api.get(`autoChat/${id}`);

  const updateChatManagementData = (data: any) => api.put(`autoChat`, data);

  const getChatManagementList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`autoChat?${params}`);
  };

  // customer Management
  const getCustomerFeedbackList = (data: any) => {
    let params = ``;
    let categoryParams = ``;
    const { categories, ...restData } = data;
    for (const key in restData) {
      let value = restData[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    categories.map((item: any) => {
      categoryParams += `categories=${item}&`;
    });
    return api.get(`customer-management/feedbacks?${params}${categoryParams}`);
  };

  const getCustomers = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/customers?${params}`);
  };
  const getCustomerWatchList = (data: any) => {
    if (data?.projectName) {
      return api.get(
        `customer-management/watchlists/${data?.cmsId}?projectName=${data?.projectName}`
      );
    }
    return api.get(`customer-management/watchlists/${data?.cmsId}`);
  };
  const getCustomerInvestmentSummary = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value && key !== "cmsId") {
        params += `${key}=${value}&`;
      }
    }
    return api.get(
      `customer-management/investment-summary/${data?.cmsId}?${params}`
    );
  };

  const getCustomerOverview = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/customer-overview?${params}`);
  };
  const getDocuments = (data: any) => {
    const customerDocumentResponse = axios.get(
      `https://cms-api.hoabl.in/hoabl-customer/doc-download?path=${data}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          Authorization: getItemFromLocalStorage(Constants.authorizationToken),
          apptype: "admin",
        },
      }
    );
    return customerDocumentResponse;
  };

  const getCustomerBookingJourney = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/bookingJourney?${params}`);
  };

  const getBlockedCustomerList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/blocked-customers?${params}`);
  };

  const unblockedCustomers = (data: any) => {
    return api.put(`customer-management/blocked-status`, data);
  };

  const investmentDetails = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/investment-details?${params}`);
  };

  //audit log
  const postAuditsServise = (data: any) => api.post("audit-logs", data);

  const getAuditsServise = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`audit-logs?${params}`);
  };

  //Form Management
  const getFormsList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`payment-forms?${params}`);
  };
  const getProjectsList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`projects?${params}`);
  };
  const getLaunchesList = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`launch-phases?${params}`);
  };
  const updateFormStatus = (data: any) => {
    return api.put(`bulk-update-payment-forms`, data);
  };

  const deleteForms = (data: any) =>
    api.delete(`bulk-delete-payment-forms`, {}, { data: data });

  const getPaymentDetails = (id: number) => {
    return api.get(`payment-forms/${id}`);
  };

  const getPaymentInvetories = (id: number) => {
    return api.get(`payment-forms/inventories/${id}`);
  };

  const createPaymentDetails = (data: any) => api.post(`payment-forms`, data);

  const updatePaymentDetails = (data: any, id: number) =>
    api.put(`payment-forms/${id}`, data);

  const refreshCustomerModuleData = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    const customersDataRefreshResponse = axios.get(
      `https://cms-api.hoabl.in/hoabl-customer/fetchAllData?${params}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          Authorization: getItemFromLocalStorage(Constants.authorizationToken),
          apptype: "admin",
        },
      }
    );
    return customersDataRefreshResponse;
  };

const getPaymentHistory  = (data: any) => {
    let params = ``;
    for (const key in data) {
      let value = data[key];
      if (value) {
        params += `${key}=${value}&`;
      }
    }
    return api.get(`customer-management/investment-list?${params}`);
  };

  return {
    login,
    logout,
    changePassword,
    forgotPassword,
    resetPassword,
    getUsers,
    getAdminsForFilters,
    getUserDashboard,
    getAdminLogs,
    getRoles,
    updateAdminStatus,
    getPromises,
    getPromiseDashboard,
    updatePromiseStatus,
    updatePromisesStatus,
    promiseStatus,
    bulkDeletePromises,
    updatePromisePriority,
    getReferrals,
    getAllFaqs,
    deleteFaq,
    updateFaq,
    bulkUpdateGeneralFaq,
    updateFaqPriority,
    updateAdmins,
    getAllUsers,
    inviteUsers,
    getModules,
    deleteUser,
    deleteUsers,
    getResourceDashboard,
    getCategories,
    getAllProjectContentList,
    getAllProjects,
    getProjectDraftList, // project-content-drafts
    addProjectContentDraft,
    updateProjectContentDraft,
    deleteProjectContentDraft,
    getProjectCollectionList,
    deleteProjectCollectionList,
    changeProjectCollectionListStatus,
    postProjectCollectionCreation,
    getProjectCollectionById,
    deleteProjectCollectionById,
    updateProjectCollectionById,
    getTestimonials,
    addTestimonial,
    changeTestimonialStatus,
    getMarketingDashboard,
    getLandingPageDashboard,
    addLandingPageUrlCreation,
    getUrls,
    changeUrlStatus,
    deleteUrl,
    faqPriorityUpdate,
    updateTestimonial,
    deleteTestimonial,
    updateTestimonialPriority,
    addUpdates,
    addBlogs,
    updateMarketingUpdates,
    updateBlogs,
    addDraftMarketingUpdates,
    addDraftBlogs,
    getSingleUpdate,
    getSingleBlog,
    deleteUpdates,
    deleteSingleBlog,
    uploadeFileToS3,
    fileDeleteFromS3,
    addNewCategory,
    createFaq,
    updateGeneralFaq,
    deleteGeneralFaq,
    deleteCategory,
    putCategory,
    getAllCategories,
    allFaqs,
    getPageDashboard,
    bulkDeleteGeneralFaq,
    postInsightsCreation,
    getAllInsightsCreation,
    updateInsightsCreation,
    updateInsightsStatuses,
    deleteInsightsCreation,
    getInsightsById,
    getAllUpdates,
    getAllBlogs,
    deleteUpdate,
    deleteBlog,
    updateStatus,
    blogStatus,
    bulkBlogDelete,
    updatePriority,
    blogPriority,
    fileUploadToS3,
    postPromises,
    updatePromises,
    postPromisesDraft,
    updatePromisesDraft,
    getAllUpdatesDrafts,
    getAllBlogsDrafts,
    removePromises,
    getAllPromises,
    getInsights,
    updateInsightsStatus,
    deleteSelectedInsights,
    updateInsightPriority,
    bulkUpdateDelete,
    getFaqList,
    deleteFaqs,
    changeFaqsStatus,
    changeFaqPriority,
    addPageManagementData,
    updatePageManagementData,
    getPageManagementData,
    getProjectContentList,
    getHoablPage1,
    updateHoablPage1,
    createHoablPage1,
    getPortfolioPage,
    updatePortfolioPage,
    addPortfolioPage,
    getProjectContents,
    getProjectHoablPage1ProjectList,
    getProjectContentCreationData,
    updateProjectContentCreationData,
    addProjectContentCreationData,
    addupCommingProjectContentCreationData,
    getUpCommigProjects,
    getUpCommigSingleProject,
    updateStatusUpcommingProject,
    updateUpcommingProject,
    filterUpCommingProject,
    fetchLeads,
    getLaunchPhasesData,
    getProjectCrmFetchUpdates,
    getAllLaunchPhasesData,
    getProjectCategories,
    bulkStatusProjectCategory,
    getProjectFaqs,
    allProjectFaqs,
    deleteProjectMappedFaq,
    verifyProjectLauchPhase,
    updateProjectPriority,
    deleteSelectedProjects,
    updateProjectsStatus,
    getNotifications,
    addNotification,
    updateNotification,
    getNotificationDashboard,
    addChatManagementData,
    getChatManagementData,
    updateChatManagementData,
    getChatManagementList,
    getCustomerFeedbackList,
    getCustomers,
    getCustomerWatchList,
    getCustomerInvestmentSummary,
    getBlockedCustomerList,
    unblockedCustomers,
    getCustomerOverview,
    getDocuments,
    getCustomerBookingJourney,
    investmentDetails,
    getAuditsServise,
    postAuditsServise,
    getFormsList,
    updateFormStatus,
    deleteForms,
    getPaymentDetails,
    getPaymentInvetories,
    createPaymentDetails,
    updatePaymentDetails,
    getProjectsList,
    getLaunchesList,

    addMediaAndPr,
    updateMediaAndPr,
    updateMediaAndPrPriority,
    changeMediaAndPrStatus,
    getMediaAndPrs,
    deleteMediaAndPr,

    getAppUpdates,
    addAppUpdate,

    refreshCustomerModuleData,
    getPaymentHistory
  };
};

export default {
  create,
};
